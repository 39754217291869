import { useLoadingSpinner } from "@context-providers/loading-spinner-provider";
import { useAppNavigation } from "@hooks/use-app-navigation";
import { QueryParams } from "@router/route-params";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

/** Content for the account preference page */
export function AccountPreferencePage(): JSX.Element {
  const { setLoadingSpinner, isLoadingSpinnerShowing } = useLoadingSpinner();
  const { navigateToRoot } = useAppNavigation();
  const [searchParams] = useSearchParams();

  // Accessing alpha flag from selector is not available at this state. This is because the store is not set up yet.
  const enableAlphaParam = searchParams.get(QueryParams.enableAlpha);

  // The loading spinner is set to true when the sphere dashboard is being initialized. It will set to false in SdbCompanyLoader.
  // However, this component is mounted independent of SdbCompanyLoader. As a result, the loading spinner will be shown forever.
  // Therefore, we need to set the loading spinner to false when this component is mounted.
  // TODO: Check if this is the correct approach. https://faro01.atlassian.net/browse/ST-2825
  useEffect(() => {
    if (isLoadingSpinnerShowing) {
      setLoadingSpinner(false);
    }
  }, [isLoadingSpinnerShowing, setLoadingSpinner]);

  /** Navigate to root page if the alpha flag is not available yet */
  if (enableAlphaParam !== "true") {
    navigateToRoot();
  }

  return <div>Preference Page</div>;
}
