import { EDecimalToHex, sphereColors } from "@styles/common-colors";
import { CSSProperties } from "@mui/material/styles/createMixins";
import { Breakpoint, SxProps, Theme } from "@mui/material";
import {
  BreakpointExtended,
  DEFAULT_TEXT_FONT_SIZE,
  PAGE_BREAKPOINTS_EXTENDED,
} from "@styles/common-styles";

/** Defines the size in pixels for the header bar */
export const HEADER_HEIGHT = "3.75rem";

/** This constant is used to show a dash when no data is available */
export const DASH = "-";

/** The width of the sidebar when in minified condition */
export const SIDEBAR_WIDTH_MINIFIED = 65;

/** The width of the sidebar when in open condition */
export const SIDEBAR_WIDTH_EXPANDED = 210;

/**
 * Defines the padding between the tab selector and the content.
 */
export const TABS_CONTENT_PADDING = "12px";

/**
 * CSS class to only show an element when the mouse is hovering over it.
 */
export const SHOW_ON_HOVER_CLASS = "show-on-hover";

/** Default CSS style to apply when the button is hovered. */
export const hoverStyle: CSSProperties = {
  backgroundColor: addTransparency({
    color: sphereColors.gray500,
    alpha: EDecimalToHex.forty,
  }),
  ...getSvgColoredIconCss(sphereColors.blue500),
};

/** Default CSS style to apply when the button is clicked. */
export const clickStyle: CSSProperties = {
  backgroundColor: addTransparency({
    color: sphereColors.gray500,
    alpha: EDecimalToHex.seventy,
  }),
  ...getSvgColoredIconCss(sphereColors.blue500),
};

/**
 * Adds a prefix hash "#" to a string if there's no one.
 * This is particularly useful for HEX colors
 *
 * @param str "The value to add the hash. E.g. FFFFFF"
 * @returns The value including the prefixed hash, e.g. "#FFFFFF".
 */
export function addPrefixHashIfMissing(str: string): string {
  const hex = str ?? "";
  return hex.startsWith("#") ? hex : `#${hex}`;
}

/**
 * Exports the CSS properties to color an SVG icon, It colors all possible svg tags,
 * except the ones that have fill="none" because those ones are not colored and
 * instead are used as content wrappers.
 *
 * @param color CSS color to be used.
 */
export function getSvgColoredIconCss(color: string): CSSProperties {
  const hexColor = addPrefixHashIfMissing(color ?? "");

  /* eslint-disable @typescript-eslint/naming-convention -- The namings are CSS selectors */
  return {
    "path:not([fill='none'])": { fill: hexColor },
    "rect:not([fill='none'])": { fill: hexColor },
    "circle:not([fill='none'])": { fill: hexColor },
    "ellipse:not([fill='none'])": { fill: hexColor },
    "line:not([fill='none'])": { fill: hexColor },
    "polyline:not([fill='none'])": { fill: hexColor },
    "polygon:not([fill='none'])": { fill: hexColor },
  };
  /* eslint-enable */
}

interface AddTransparencyProps {
  /**
   * Color to add transparency. Should be in HEX format
   * including the prefix "#". E.g. "#FFFFFF"
   */
  color: string;

  /** Value for transparency in HEX format, ranging from "00" to "FF" */
  alpha: EDecimalToHex;
}

/**
 * Adds the alpha value for transparency to an HEX color.
 *
 * @returns The final HEXA color.
 */
export function addTransparency({
  color,
  alpha,
}: AddTransparencyProps): string {
  return `${addPrefixHashIfMissing(color ?? "")}${alpha ?? ""}`;
}

/** Copies some text into to the clipboard */
export function copyToClipboard(text: string): void {
  if (navigator && navigator.clipboard) {
    // If the native function is available, use it.
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
    navigator.clipboard.writeText(text);
    return;
  }

  // Otherwise this is a workaround for browsers that don't support the native function.
  const copyArea = document.createElement("textarea");
  copyArea.value = text;
  // Needed so that it works in all browsers that generally support document.execCommand('copy').
  document.body.appendChild(copyArea);
  copyArea.select();

  // Should never throw an exception according to https://developer.mozilla.org/de/docs/Web/API/Document/execCommand
  document.execCommand("copy");
  document.body.removeChild(copyArea);
}

/**
 * Returns a CSS box-shadow for a bottom border.
 *
 * @param thickness The thickness of the border.
 * @param color The color of the border.
 * @returns The CSS box-shadow.
 */
export function getBottomBorderWithBoxShadow({
  thickness = "1px",
  color = sphereColors.blue500,
}: {
  thickness?: string | number;
  color?: string | number;
}): CSSProperties["boxShadow"] {
  return `0 ${thickness} 0 0 ${color}`;
}

/**
 * Exports the border CSS property.
 *
 * @param color Color of the border.
 * @param width Optional width of the border. Defaults to "1px".
 * @returns CSS property to be used in border.
 */
export function border(color: string, width = "1px"): CSSProperties["border"] {
  return `${width} solid ${color}`;
}

/** Width of the cards depending on the breakpoint */
export const cardWidth: {
  [key in Extract<Breakpoint, "xs" | "sm">]: CSSProperties["width"];
} = {
  xs: "100%",
  sm: "250px",
};

/** Exports properties to be used in cards to show projects, groups, etc. */
export const cardProperties: {
  height: CSSProperties["height"];
  marginTop: CSSProperties["marginTop"];
  boxShadow: CSSProperties["boxShadow"];
  borderRadius: CSSProperties["borderRadius"];
  creationDatePaddingTop: CSSProperties["paddingTop"];
} = {
  height: "294px",
  marginTop: "20px",
  boxShadow: `0 0 0 1px ${sphereColors.gray200}`,
  borderRadius: "4px",
  creationDatePaddingTop: "14px",
};

/**
 * Returns the breakpoint name based on the width of the screen.
 *
 * @param width The width of the screen.
 * @returns The name of the breakpoint.
 */
export function getBreakpointName(width: number): BreakpointExtended {
  for (let i = 0; i < Object.values(PAGE_BREAKPOINTS_EXTENDED).length; i++) {
    if (width < Object.values(PAGE_BREAKPOINTS_EXTENDED)[i]) {
      return Object.keys(PAGE_BREAKPOINTS_EXTENDED)[
        i - 1
      ] as BreakpointExtended;
    }
  }
  return "xxxl";
};

/**
 * Defines the style properties for different parts of an Autocomplete component
 */
interface AutocompleteStylesProps {
  /** Styles for the dropdown option items */
  option: {
    sx: SxProps<Theme>;
  };
  
  /** Styles for the dropdown no options found message */
  noOption: {
    sx: SxProps<Theme>;
  };
  
  /** Styles for the text content within list items */
  listItemText: {
    sx: SxProps<Theme>;
  };

  /** Styles for the dropdown paper container */
  paper: {
    sx: SxProps<Theme>;
  };

  /** Styles for the input field */
  input: {
    sx: SxProps<Theme>;
  };

  /** Styles for icons container */
  iconWrapper: {
    sx: SxProps<Theme>;
  };
}

/**
 * Returns the styles for the Autocomplete component.
 */
export function getAutocompleteStyles(): AutocompleteStylesProps {
  const selectedOptionStyles = {
    backgroundColor: addTransparency({
      color: sphereColors.gray500,
      alpha: EDecimalToHex.forty,
    }),
    "& .MuiListItemText-primary": {
      color: sphereColors.blue500,
    },
  };
  const styles = {
    option: {
      sx: {
        my: 0,
        textWrap: "wrap",
        "&:not(:first-of-type)": {
            mt: "4px",
          },
        "&.MuiAutocomplete-option": {
            padding: "8px",
          },
        "& .MuiAutocomplete-listbox": {
          padding: 0,
        }, 
        "&.MuiAutocomplete-option[aria-selected='true']": {
          ...selectedOptionStyles,
        },
        "&.MuiAutocomplete-option[aria-selected='true'].Mui-focused": {
          ...selectedOptionStyles,
        },
        "&:hover": {
          backgroundColor: addTransparency({
            color: sphereColors.gray500,
            alpha: EDecimalToHex.twentySix,
          }),
        },
        "&:hover .MuiListItemText-primary": {
          color: sphereColors.blue500,
        },
      },
    },
    noOption: {
      sx: {
        fontSize: "12px",
        color: sphereColors.gray800,
        opacity: 1,
        textDecoration: "none",
      },
    },
    listItemText: {
      sx: {
        height: "38px",
        "& .MuiListItemText-primary": {
          fontSize: DEFAULT_TEXT_FONT_SIZE,
          fontWeight: 600,
          color: sphereColors.gray800,
        },
        "& .MuiListItemText-secondary": {
          fontSize: DEFAULT_TEXT_FONT_SIZE,
          fontWeight: 400,
          color: sphereColors.gray600,
        },
      },
    },
    paper: {
      sx: {
        paddingX: "8px",
        gap: "10px",
        border: `1px solid ${sphereColors.gray200}`,
        boxShadow: `0px 3px 3px 0px ${addTransparency({
          color: sphereColors.gray600,
          alpha: EDecimalToHex.twenty,
        })}`,
        borderRadius: "4px",
      },
    },
    input: {
      sx: {
        "& .MuiOutlinedInput-root": { paddingY: "2px" },
      },
    },
    iconWrapper: {
      sx: {
        height: "22px",
        width: "22px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& svg": {
          height: "16px",
          width: "16px",
        },
      },
    },
  };

  return styles;
};