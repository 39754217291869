import { Autocomplete, Box } from "@mui/material";
import { SyntheticEvent, useEffect, useMemo } from "react";
import { APITypes, SphereDashboardAPITypes } from "@stellar/api-logic";
import { FaroSimpleTextField } from "@components/common/faro-text-field/faro-simple-text-field";
import { GroupAutocompleteItem } from "@components/common/group-autocomplete/group-autocomplete-item";
import { sphereColors } from "@styles/common-colors";
import GenericClose from "@assets/icons/generic-close_l.svg?react";
import SelectorArrow from "@assets/icons/selector_arrow.svg?react";

export type Group = {
  /** The group ID */
  id: APITypes.GroupId;

  /** The title of group */
  title: string;

  /** The sub title of group */
  subtitle: string;

  /** The value of group */
  value: APITypes.GroupId;

  /** The image thumbnail of group */
  imageUrl?: string;
};

interface GroupProps {
  /** The function to be called when the user selects a project */
  callbackOnGroupSelected: (value: APITypes.GroupId | undefined) => void;

  /** All the company groups */
  companyGroups: SphereDashboardAPITypes.ICompanyGroup[];

  /** Select the company automatically */
  selectedGroupId?: APITypes.GroupId;

  /** Placeholder for the input */
  placeholder?: string;
}

/**
 * Defines the maximum items to be displayed in the autocomplete menu as suggestions.
 * The number should be low enough to fit them all at once in the screen.
 */
const MAX_ITEMS_TO_DISPLAY = 6;

/**
 * Sorts the options by label.
 */
function sortByLabel(a: Group, b: Group): number {
  return a.title.toLowerCase().localeCompare(b.title.toLowerCase());
}

/**
 * The GroupAutoComplete component enables users to select a group from a dynamic list of options
 * based on provided company groups. It offers autocomplete functionality with custom rendering for
 * options and icons, enhancing the user experience in selecting groups effortlessly.
 */
export function GroupAutoComplete({
  callbackOnGroupSelected,
  companyGroups,
  selectedGroupId,
  placeholder,
}: GroupProps): JSX.Element {
  /** Reformation company groups data for stellar autocomplete component */
  const groups = useMemo(() => {
    return companyGroups.map((group) => {
      return {
        id: group.id,
        title: group.name,
        subtitle: "",
        value: group.id,
        imageUrl: group.imageUrl === "" ? undefined : group.imageUrl,
      };
    });
  }, [companyGroups]);

  useEffect(() => {
    if (groups.length === 1) {
      const groupId = groups[0].value.toString();
      callbackOnGroupSelected(groupId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- multiple re-renders with the callbackOnGroupSelected
  }, [groups]);

  /**
   *  Function triggered whenever an option is selected in the Autocomplete component.
   *  Calls the onGroupSelected function to fetch the members belonging to the selected group
   *  and stores them in the groupMembers state variable.
   *
   * @param _ The synthetic event that occurred.
   * @param newValue The value of the option selected in the Autocomplete. It can be an object of type Group or null.
   */
  function handleAutocompleteChange(
    _: SyntheticEvent<Element, Event>,
    newValue: Group | null
  ): void {
    if (newValue) {
      callbackOnGroupSelected(newValue.id);
    } else {
      callbackOnGroupSelected(undefined);
    }
  }

  const selectedValues = useMemo(
    () => groups.find((group) => group.id === selectedGroupId),
    [groups, selectedGroupId]
  );

  return (
    <Autocomplete
      defaultValue={groups.length === 1 ? groups[0] : undefined}
      data-testid="group-auto-complete-test-id"
      id="groups-new-project"
      options={groups}
      onChange={handleAutocompleteChange}
      getOptionLabel={(option) => option.title}
      sx={{ width: "100%", overflow: "hidden" }}
      autoHighlight
      value={selectedValues || null}
      popupIcon={
        <Box
          sx={{
            height: "24px",
            width: "24px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",

            "& svg": {
              height: "18px",
              width: "18px",
            },
          }}
        >
          <SelectorArrow />
        </Box>
      }
      clearIcon={
        <Box
          sx={{
            height: "24px",
            width: "24px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",

            "& svg": {
              height: "18px",
              width: "18px",
            },
          }}
        >
          <GenericClose />
        </Box>
      }
      isOptionEqualToValue={(option, value) => option.title === value.title}
      filterOptions={(options, state) => {
        if (!state.inputValue) {
          // If the user didn't type anything, show all the options sorted alphabetically.
          return options.sort(sortByLabel);
        }

        const lowerInputValue = state.inputValue.toLowerCase();
        const filteredOptions = options.filter(
          (option) =>
            option.title.toLowerCase().includes(lowerInputValue) ||
            option.title.toLowerCase()?.includes(lowerInputValue)
        );

        // Limit the number of displayed options (e.g., to the first 10 matches)
        return filteredOptions.slice(0, MAX_ITEMS_TO_DISPLAY).sort(sortByLabel);
      }}
      renderOption={(props, option, state) => (
        <Box component={"li"} {...props} key={`group-item-${state.index}`}>
          <GroupAutocompleteItem
            title={option.title}
            image={option.imageUrl}
            inputValue={state.inputValue}
          />
        </Box>
      )}
      renderInput={(params) => (
        <FaroSimpleTextField
          {...params}
          autoFocus={false}
          variant="outlined"
          placeholder={
            placeholder ? placeholder : "Assign this project to a group..."
          }
          sx={{
            "& .MuiOutlinedInput-root": { paddingY: "2px" },
            height: "40px",
          }}
        />
      )}
      noOptionsText={
        <Box
          component="div"
          sx={{
            fontSize: "12px",
            color: sphereColors.gray800,
            opacity: 1,
            textDecoration: "none",
          }}
        >
          No groups found!
        </Box>
      }
    />
  );
}
