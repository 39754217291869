import { Alert, Grid, Stack } from "@mui/material";
import { useState } from "react";
import {
  APITypes,
  CoreAPITypes,
  SphereDashboardAPITypes,
} from "@stellar/api-logic";
import {
  FaroDialog,
  SPACE_ELEMENTS_OF_MODAL,
} from "@components/common/dialog/faro-dialog";
import { isValidEmail } from "@utils/member-utils";
import { useAppDispatch } from "@store/store-helper";
import { useAppParams } from "@router/router-helper";
import { useCoreApiClient } from "@api/use-core-api-client";
import { setMany } from "@store/members/members-slice";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { useToast } from "@hooks/use-toast";
import { EnableGroupCreationCheckbox } from "@components/groups/enable-group-creation-checkbox";
import { createGroupsPermission } from "@utils/user-utils";
import { MembersAutocomplete } from "@components/common/members-autocomplete/members-autocomplete";
import { AutoCompleteMessage } from "@components/common/faro-text-field/faro-text-field-message";
import { FaroButtonContained } from "@components/common/faro-button-contained";
import { FaroButton } from "@components/common/faro-button";
import { SelectCompanyRole } from "@components/role/select-company-role";
import { GroupAutoComplete } from "@components/common/group-autocomplete/group-autocomplete";
import { useGroups } from "@hooks/use-groups";
import { LabelWithHelp } from "@components/common/label-with-help";
import { sentryCaptureError } from "@utils/sentry-utils";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { WorkspaceEvents } from "@utils/track-event/track-event-list";

interface Props {
  /**
   * Determines the variant of the invite member button
   *  - "outlined" (default): Contained button with white background and blue border
   *  - "filled": Normal button with blue background
   */
  buttonVariant?: "outlined" | "filled";
}

/** Renders the invite member button and the functionality in member page */
export function InviteMemberToCompany({
  buttonVariant = "outlined",
}: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const { companyId } = useAppParams();
  const coreApiClient = useCoreApiClient();
  const { handleErrorWithToast } = useErrorContext();
  const { showToast } = useToast();
  const companyGroups = useGroups();
  const { trackEvent } = useTrackEvent();

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [addedEmails, setAddedEmails] = useState<string[]>([]);
  // TODO: https://faro01.atlassian.net/browse/ST-646
  const [selectedRole, setSelectedRole] =
    useState<SphereDashboardAPITypes.IAssignmentCompanyRole>(
      CoreAPITypes.EUserCompanyRole.member as SphereDashboardAPITypes.IAssignmentCompanyRole
    );
  const [selectedGroupId, setSelectedGroupId] = useState<
    APITypes.GroupId | undefined
  >(undefined);
  const [shouldCreateGroups, setShouldCreateGroups] = useState<boolean>(false);
  const [message, setMessage] = useState<AutoCompleteMessage | undefined>();

  // Selected roles that needs to show group list when inviting them
  const shouldShowGroupList =
    selectedRole === CoreAPITypes.EUserCompanyRole.companyManager ||
    selectedRole === CoreAPITypes.EUserCompanyRole.projectManager;

  /**
   * Maximum amount of members that can be invited at once and the backend will
   * do it synchronously.
   * Meaning that the promise will be resolved after all the members are invited.
   * TODO: https://faro01.atlassian.net/browse/ST-2829
   */
  const MAX_MEMBERS_SYNC = 10;
  /**
   * Workaround to prevent inviting more than 10 members at a time without a workspace role.
   * This is because the operation would be done async in the backend and the workaround to
   * resend the invitation emails would not work.
   * TODO: https://faro01.atlassian.net/browse/ST-2829
   */
  const shouldDisableMaxMembers =
    addedEmails.length > MAX_MEMBERS_SYNC &&
    selectedRole === CoreAPITypes.EUserCompanyRole.member as SphereDashboardAPITypes.IAssignmentCompanyRole;

  function onOpenInviteDialog(): void {
    setAddedEmails([]);
    setIsDialogOpen(true);
    setSelectedRole(CoreAPITypes.EUserCompanyRole.member as SphereDashboardAPITypes.IAssignmentCompanyRole);
    setSelectedGroupId(undefined);
  }

  /**
   * This is a workaround to resend invitations for members.
   * Currently the backend does not send email invitation when a member is added to a company
   * without a workspace role.
   * This function makes sure that the invitation is actually sent to the member.
   * TODO: https://faro01.atlassian.net/browse/ST-2829
   *
   * @param companyId - The company id where the members are being invited
   */
  async function resendInvitationForMembers(companyId: string): Promise<void> {
    const resendPromises = addedEmails.map((email) =>
      coreApiClient.V3.SDB.resendCompanyMembershipInvite({
        companyId,
        identity: email,
      })
    );
    try {
      await Promise.all(resendPromises);
    } catch (error) {
      sentryCaptureError({ error, title: "Error resending invitations for multiple members" });
    }
  }

  /** This function creates payload and call the appropriate route */
  async function createPayloadAndSendInvite({
    shouldAddMemberToGroup,
    companyId,
  }: {
    shouldAddMemberToGroup: boolean;
    companyId: string;
  }): Promise<void> {
    // Additional permission to be added if user should be able to create groups
    const permission: SphereDashboardAPITypes.ICompanyPermission | undefined =
      shouldCreateGroups &&
      selectedRole === CoreAPITypes.EUserCompanyRole.companyManager
        ? createGroupsPermission
        : undefined;

    if (shouldAddMemberToGroup && selectedGroupId) {
      const payload: SphereDashboardAPITypes.IBulkMemberInvitePayload<"group"> =
        {
          assignments: [
            {
              identities: addedEmails,
              role: selectedRole,
              permission,
            },
          ],
        };

      const response: SphereDashboardAPITypes.IBulkMemberInviteResponse<"group"> =
        await coreApiClient.V3.SDB.addMembersToGroup(
          companyId,
          selectedGroupId,
          payload
        );

      respondToUser(response);
    } else {
      const payload: SphereDashboardAPITypes.IBulkMemberInvitePayload<"company"> =
        {
          assignments: [
            {
              identities: addedEmails,
              role: selectedRole,
              permission,
            },
          ],
        };

      const response: SphereDashboardAPITypes.IBulkMemberInviteResponse<"company"> =
        await coreApiClient.V3.SDB.addMembersToCompany(companyId, payload);
      
      // TODO: https://faro01.atlassian.net/browse/ST-2829
      if (selectedRole === CoreAPITypes.EUserCompanyRole.member as SphereDashboardAPITypes.IAssignmentCompanyRole) {
        await resendInvitationForMembers(companyId);
      }

      respondToUser(response);
    }
  }

  /** This function checks the backend response and show related toast message based on it */
  function respondToUser(
    response: SphereDashboardAPITypes.IBulkMemberInviteResponse<
      "company" | "group"
    >
  ): void {
    // Add invited member to the store. response.data.members exist when the response is either success or warning
    if (response.data.members) {
      dispatch(
        setMany(
          response.data.members.filter((member) => member.kind === "user")
        )
      );
    }

    // Show response messages
    const responseMessage = response.data.message;
    const additionalMessages: string[] = [];
    if (response.errorData) {
      response.errorData.details.failedInvitations?.forEach((error) => {
        additionalMessages.push(error.message);
      });

      response.errorData.details.failedPermissions?.forEach((error) => {
        additionalMessages.push(error.message);
      });
    }

    if (response.status === "success") {
      showToast({
        type: "success",
        message: "Invitations sent",
        description: additionalMessages,
      });
    } else if (response.status === "warning") {
      showToast({
        type: "warning",
        message: responseMessage,
        description: additionalMessages.map((message, index) => (
          <li key={index}>{message}</li>
        )),
      });
    } else if (response.status === "error") {
      showToast({
        type: "error",
        message: responseMessage,
        description: additionalMessages.map((message, index) => (
          <li key={index}>{message}</li>
        )),
      });
    }
  }

  /** Submit members to be invited */
  // eslint-disable-next-line @typescript-eslint/require-await -- Please review lint error
  async function handleConfirm(): Promise<void> {
    setIsDialogOpen(false);
    trackEvent({
      name: WorkspaceEvents.inviteMembers,
      props: {
        numberOfMembers: addedEmails.length,
        role: selectedRole,
      },
    });

    try {
      if (companyId && addedEmails.length) {
        await createPayloadAndSendInvite({
          shouldAddMemberToGroup: !!selectedGroupId,
          companyId,
        });
      }
    } catch (error) {
      handleErrorWithToast({
        id: `inviteMember-${Date.now().toString()}`,
        title: "Cannot invite member(s)",
        error,
      });
    }
  }

  function shouldDisableConfirmButton(): boolean {
    if (!addedEmails.length) {
      return true;
    }
    if (shouldShowGroupList && !selectedGroupId) {
      return true;
    }
    if (shouldDisableMaxMembers) {
      return true;
    }
    return false;
  }

  function handleChange(options: string[]): void {
    setAddedEmails(options.filter(isValidEmail));
  }

  function onInputChange(value: string): void {
    if (!value) {
      // Removing message on clearing the search text
      setMessage(undefined);
    } else if (!isValidEmail(value)) {
      // Showing error message for new and invalid email
      setMessage({ type: "error", helperText: "Enter a valid email" });
    } else {
      setAddedEmails([...addedEmails, value]);
      setMessage(undefined);
    }
  }

  return (
    <>
      {buttonVariant === "filled" ? (
        <FaroButton onClick={onOpenInviteDialog}>Invite members</FaroButton>
      ) : (
        <FaroButtonContained onClick={onOpenInviteDialog}>
          Invite members
        </FaroButtonContained>
      )}
      <FaroDialog
        title="Invite members to workspace"
        confirmText="Send Invite"
        open={isDialogOpen}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises -- Please review lint error
        onConfirm={handleConfirm}
        isConfirmDisabled={shouldDisableConfirmButton()}
        onClose={() => setIsDialogOpen(false)}
      >
        <Grid maxWidth="100%" width="70vw">
          <Stack marginBottom={SPACE_ELEMENTS_OF_MODAL}>
            <SelectCompanyRole
              selectedRole={selectedRole}
              shouldAllowAllRolesSelection={true}
              onChange={setSelectedRole}
              isTableCell={false}
            />
          </Stack>
          {shouldShowGroupList && (
            <Stack marginBottom={SPACE_ELEMENTS_OF_MODAL}>
              <LabelWithHelp title="Group" isRequired />
              <GroupAutoComplete
                companyGroups={companyGroups}
                callbackOnGroupSelected={setSelectedGroupId}
                selectedGroupId={selectedGroupId}
                placeholder={"Please select a group..."}
              />
            </Stack>
          )}
          <Stack>
            <MembersAutocomplete
              options={[]}
              handleChange={handleChange}
              validateNewOption={isValidEmail}
              labelTitle="Emails"
              onInputChange={onInputChange}
              message={message}
              hasAutoFocus={true}
            />
          </Stack>

          <EnableGroupCreationCheckbox
            selectedRole={selectedRole}
            shouldCreateGroups={shouldCreateGroups}
            membersCount={addedEmails.length}
            setShouldCreateGroups={setShouldCreateGroups}
          />
          {
            // This error message will be shown when user tries to invite more than 10 members without a workspace role
            // TODO: https://faro01.atlassian.net/browse/ST-2829
            shouldDisableMaxMembers && (
              <Alert severity="error">You can only invite up to 10 member at a time</Alert>
            )
          }
        </Grid>
      </FaroDialog>
    </>
  );
}
