import { useNavigate } from "react-router";
import {
  generateGroupDetailsRoute,
  generateProjectDetailsRoute,
  generateProjectsRoute,
  generateMemberProfileRoute,
  generateGroupsRoute,
  generateTeamDetailsRoute,
  getPreservedQueryParams,
  generateIntegrationsRoute,
  AppRoutes,
} from "@router/router-helper";
import { getInspectAndPublishToolUrl } from "@pages/project-details/project-data-management/data-management-utils";
import { APITypes } from "@stellar/api-logic";
import { ProjectArchivingState } from "@custom-types/project-types";
import {
  BaseCompanyIdProps,
  BaseGroupIdProps,
  BaseProjectIdProps,
  BaseTeamIdProps,
} from "@custom-types/sdb-company-types";
import { MemberTabs, QueryParams } from "@router/route-params";
import { useLocation, useSearchParams } from "react-router-dom";
import { OPEN_PROJECT_TARGET_ATTRIBUTE } from "@utils/project-utils";

interface NavigateToProjects extends BaseCompanyIdProps {
  /** The archiving state of the selected project */
  projectStatus?: ProjectArchivingState;
}

type NavigateToProjectDetail = BaseCompanyIdProps & BaseProjectIdProps;

type NavigateToGroupDetails = BaseCompanyIdProps & BaseGroupIdProps;

type NavigateToTeamDetails = BaseCompanyIdProps & BaseTeamIdProps;

type NavigateToIntegrationsPage = BaseCompanyIdProps;

interface NavigateToInspectAndPublishTool extends BaseProjectIdProps {
  /** The ID of the registration revision */
  registrationId: string;
}

interface NavigateToMemberProfile extends BaseCompanyIdProps {
  /** The ID of the selected member */
  memberId: APITypes.UserId;

  /** The tab to be selected in the member profile page */
  memberTabs?: MemberTabs;
}

interface SetUrlParamProps {
  /** They name of the query parameter to be set */
  key: QueryParams;

  /** The value of the query parameter to be set */
  value: string | null;
}

export interface UseAppNavigation {
  /** navigates to the root of the app which is the workspace selection page. */
  navigateToRoot(): void;

  /** Navigates to the projects */
  navigateToProjects({ companyId, projectStatus }: NavigateToProjects): void;

  /** navigates to the details of a single project */
  navigateToProjectDetail({
    companyId,
    projectId,
  }: NavigateToProjectDetail): void;

  /** navigates to the groups page */
  navigateToGroups({ companyId }: BaseCompanyIdProps): void;

  /** navigates to the details of a single group */
  navigateToGroupDetails({ companyId, groupId }: NavigateToGroupDetails): void;

  /** navigates to the details of a single member */
  navigateToMemberProfile({
    companyId,
    memberId,
  }: NavigateToMemberProfile): void;

  /** Navigates to the details of a team */
  navigateToTeamDetails({ companyId, teamId }: NavigateToTeamDetails): void;

  /** Navigates to the inspect and publish tool */
  navigateToInspectAndPublishTool({
    projectId,
    registrationId,
  }: NavigateToInspectAndPublishTool): void;

  /** Navigates to the integrations page */
  navigateToIntegrationsPage({ companyId }: NavigateToIntegrationsPage): void;

  /** Navigates to the account preference page */
  navigateToAccountPreferencePage(): void;

  /** Sets a query parameter in the URL */
  setUrlParam({ key, value }: SetUrlParamProps): void;

  /** Gets a query parameter from the URL */
  getQueryParam(key: QueryParams): string | null;
}

/**
 * A hook that gathers all the navigation and routing in the app
 */
export function useAppNavigation(): UseAppNavigation {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  // Navigates to a new path while preserving the query parameters from the current URL
  function navigateWithPreservedParams(path: string): void {
    const preservedSearch = getPreservedQueryParams(location.search);
    const cleanedPath = path.split("?")[0];
    navigate({
      pathname: cleanedPath,
      search: preservedSearch,
    });
  }

  function navigateToRoot(): void {
    navigateWithPreservedParams("/");
  }

  function navigateToProjects({
    companyId,
    projectStatus,
  }: NavigateToProjects): void {
    const path = generateProjectsRoute(companyId, projectStatus);
    navigateWithPreservedParams(path);
  }

  function navigateToProjectDetail({
    companyId,
    projectId,
  }: NavigateToProjectDetail): void {
    const path = generateProjectDetailsRoute(companyId, projectId);
    navigateWithPreservedParams(path);
  }

  function navigateToGroups({ companyId }: BaseCompanyIdProps): void {
    const path = generateGroupsRoute(companyId);
    navigateWithPreservedParams(path);
  }

  function navigateToGroupDetails({
    companyId,
    groupId,
  }: NavigateToGroupDetails): void {
    const path = generateGroupDetailsRoute(companyId, groupId);
    navigateWithPreservedParams(path);
  }

  function navigateToMemberProfile({
    companyId,
    memberId,
    memberTabs,
  }: NavigateToMemberProfile): void {
    const path = generateMemberProfileRoute(companyId, memberId, memberTabs);
    navigateWithPreservedParams(path);
  }

  function navigateToTeamDetails({
    companyId,
    teamId,
  }: NavigateToTeamDetails): void {
    const path = generateTeamDetailsRoute(companyId, teamId);
    navigateWithPreservedParams(path);
  }

  function navigateToInspectAndPublishTool({
    projectId,
    registrationId,
  }: NavigateToInspectAndPublishTool): void {
    const path = getInspectAndPublishToolUrl(projectId, registrationId);
    window.open(path, OPEN_PROJECT_TARGET_ATTRIBUTE);
  }

  function navigateToIntegrationsPage({
    companyId,
  }: NavigateToIntegrationsPage): void {
    const path = generateIntegrationsRoute(companyId);
    navigateWithPreservedParams(path);
  }

  function navigateToAccountPreferencePage(): void {
    navigateWithPreservedParams(AppRoutes.AccountPreferenceRoute);
  }

  /** Sets a query parameter in the URL */
  function setUrlParam({ key, value }: SetUrlParamProps): void {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    if (value === null) {
      newSearchParams.delete(key);
    } else {
      newSearchParams.set(key, value);
    }
    setSearchParams(newSearchParams);
  }

  /**
   * Returns the value of a query parameter from the URL
   */
  function getQueryParam(key: QueryParams): string | null {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get(key);
  }

  return {
    navigateToRoot,
    navigateToProjects,
    navigateToProjectDetail,
    navigateToGroups,
    navigateToGroupDetails,
    navigateToMemberProfile,
    navigateToTeamDetails,
    navigateToInspectAndPublishTool,
    navigateToIntegrationsPage,
    navigateToAccountPreferencePage,
    setUrlParam,
    getQueryParam,
  };
}
